import { mapActions } from "vuex";
import FilterMixin from "./../mixins/filter";
export default {
  mixins: [FilterMixin],
  created() {
    this.setHeaders();
  },
  data: () => ({
    path: null,
    headers: [],
    items: [],
    itemPerPage: 20,
    meta: {},
    loading: {
      list: false,
      refresh: false,
      filter: false,
      delete: false
    },
    dialog: {
      form: {
        display: false,
        title: null,
        success: null,
        update: false
      },
      filter: {
        display: false,
        title: null,
        maxWidth: 500
      },
      show: {
        display: false,
        title: null,
        maxWidth: 500
      }
    },
    filters: {},
    pagination: {},
    footerProps: {
      itemsPerPageOptions: [20, 50, 100],
      showFirstLastPage: true,
    },
  }),
  methods: {
    setHeaders() {
      this.headers = [];
    },
    async getItems() {
      if (!this.path) return;
      let self = this;
      const qb = this.getQueryBuilder();
      const { sortBy, sortDesc } = this.pagination;

      this.loading.list = true;

      if (sortBy.length > 0 && sortDesc.length > 0) {
        sortBy.forEach((s, k) => {
          qb.sortBy({ field: s, order: sortDesc[k] ? "DESC" : "ASC" });
        });
      }

      if (this.filters.length) {
        for (const filter of this.filters) {
          qb.setFilter(filter);
        }
      }

      try {
        const response = await this.request({
          url: `${this.path}?${qb.query()}`,
          params: self.getAdditionalParams(),
        });
        const data = response.data;

        this.items = data.data;
        this.meta = {
          total: data.total,
        };

        this.loading.list = false;
        return response;
      } catch (error) {
        console.log("error to get items list", error);
        throw error;
      }
    },
    async refreshItems() {
      this.loading.refresh = true;
      await this.getItems();
      this.loading.refresh = false;
    },
    filterItems() {},
    getQueryBuilder() {
      return this.$requestBuilder.create();
    },
    getAdditionalParams() {
      return {};
    },
    async applyFilter(form) {
      this.dialog.filter.display = false;
      this.filters = this.filterApply(form);
      this.loading.filter = true;
      try {
        await this.getItems();
      } catch (error) {
        // empty
      }
      this.loading.filter = false;
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  watch: {
    pagination: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
};
